import { FormEvent } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { i18n } from '../../app/i18n';
import { BoxWithHeading } from '../../common/Box';
import { ButtonWithBottomMargin } from '../../common/buttons';
import FlashMessage from '../../common/FlashMessage/FlashMessage';
import { Centered, MainContainer } from '../../common/layout';
import Checkbox from '../Checkbox/checkbox';
import { FinnairAccountType } from '../ExportData/exportData.interface';
import HeroImage from '../HeroImage';
import { Content, Paragraph } from './_view';
import {
  marketingContentMapStateToProps,
  marketingConsentMapDispatchToProps,
  MarketingConsentProps,
  CheckBoxState,
} from './MarketingConsentScreen';
import { uiEvent } from '../../dataLayer';

const getAccountType = (tier: string): FinnairAccountType => {
  return tier === 'FINNAIRID' ? FinnairAccountType.FACCOUNT : FinnairAccountType.FPLUS;
};

class LoggedInContent extends React.Component<MarketingConsentProps, CheckBoxState> {
  constructor(props: MarketingConsentProps) {
    super(props);
    this.state = {
      confirmSave: !!props.confirmSave,
    };
  }
  handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    uiEvent('marketing-consents', 'submit', 'save-marketing-consents', 'form');
    this.props.handleSubmitMarketingConsents(
      this.props.profile ? getAccountType(this.props.profile.tier) : FinnairAccountType.FPLUS
    );
  };

  componentDidMount() {
    const isLoggedIn = this.props.loggedIn;
    if (isLoggedIn) {
      this.props.fetchUserProfile(this.props.token);
    }
  }

  componentDidUpdate(prevProps: MarketingConsentProps, _prevState: any) {
    // We got the profile, lets fetch marketing consents
    if (this.props.profile && !prevProps.profile) {
      const accountType: FinnairAccountType = getAccountType(this.props.profile.tier);
      this.props.fetchConsentsInitData(accountType);
    }
  }

  render() {
    if (!this.props.loggedIn) {
      return <Redirect to="/marketing-consent" />;
    }
    if (!this.props.profile || !this.props.marketingConsentGroup || !this.props.marketingConsentGroup.consents) {
      return <div>{i18n('marketingConsentPage.loadingText')}</div>;
    }
    const reConsentRequired: boolean = this.props.marketingConsentGroup.consents.some(
      (item: any) => item.reConsentRequired
    );
    return (
      <Content>
        <Paragraph>{i18n('marketingConsentPage.beforeCheckboxesText')}</Paragraph>
        <Paragraph dangerouslySetInnerHTML={{ __html: this.props.marketingConsentGroup.introductionText || '' }} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="consent-texts">
            {this.props.marketingConsentGroup.consents.map((consentItem: any) => (
              <Checkbox
                id={consentItem.consentTextId}
                key={consentItem.consentTextId}
                label={consentItem.text || ''}
                htmlContent={consentItem.text}
                isChecked={() => this.props.checkedMarketingConsents[consentItem.consentTextId]}
                handleCheckboxChange={this.props.handleToggleMarketingConsent}
              />
            ))}
          </div>

          <Paragraph
            dangerouslySetInnerHTML={{ __html: this.props.marketingConsentGroup.profileAfterwordText || '' }}
          />

          {reConsentRequired ? (
            <Checkbox
              id={'confirm'}
              label={'confrim'}
              htmlContent={i18n('marketingConsentPage.confirmSave')}
              isChecked={() => this.state.confirmSave}
              handleCheckboxChange={this._toggle}
            />
          ) : null}
          <Centered>
            <ButtonWithBottomMargin
              disabled={reConsentRequired ? !this.state.confirmSave : this.state.confirmSave}
              type="submit"
            >
              {i18n('marketingConsentPage.saveBtn')}
            </ButtonWithBottomMargin>
          </Centered>
        </form>
        <FlashMessage visible={this.props.changesSavedSuccessfully} message={i18n('cookieConsentPage.changesSaved')} />
      </Content>
    );
  }

  _toggle = () => {
    return this.setState({ confirmSave: !this.state.confirmSave });
  };
}

export class MarketingPreferencesScreen extends React.Component<MarketingConsentProps> {
  render() {
    return (
      <MainContainer>
        <HeroImage
          title={i18n('marketingConsentPage.heroImage.title')}
          ingress={i18n('marketingConsentPage.heroImage.ingress')}
        />
        <BoxWithHeading title={i18n('marketingConsentPage.title')}>
          <LoggedInContent {...this.props} />
        </BoxWithHeading>
      </MainContainer>
    );
  }
}

export default connect(marketingContentMapStateToProps, marketingConsentMapDispatchToProps)(MarketingPreferencesScreen);
