import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MainContainer } from '../../common/layout';
import { FinnairAccountType, UserProfile } from '../ExportData/exportData.interface';
import { fetchUserProfile, FetchUserProfile } from '../ExportData/ExportDataActions';
import HeroImage from '../HeroImage';
import { LoginPrompt } from '../Login/LoginPrompt';
import { connect } from 'react-redux';
import { RootState } from '../../app/state.interface';
import {
  createFetchDataFromBackendAction,
  createSubmitMarketingConsentsAction,
  HandleMarketingConsentsChange,
  toggleMarketingConsent,
} from './MarketingConsentActions';
import { i18n } from '../../app/i18n';
import { safeGet } from '../../utils/safe-get';

export type MarketingConsentProps = RouteComponentProps<void> & {
  checkedMarketingConsents: { [label: string]: boolean };
  handleToggleMarketingConsent: (item: string) => void;
  fetchConsentsInitData: (accountType: FinnairAccountType) => void;
  handleSubmitMarketingConsents: HandleMarketingConsentsChange;
  fetchUserProfile: FetchUserProfile;
  marketingConsentGroup: any; // TODO Fix the types as soon as innovation sprint is over
  loggedIn: boolean;
  confirmSave: false;
  buttonStatus: boolean;
  changesSavedSuccessfully: boolean;
  token: string;
  profile: UserProfile | undefined;
};

export type CheckBoxState = {
  confirmSave: boolean;
};

export class MarketingConsentScreen extends React.Component<MarketingConsentProps> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { loggedIn } = this.props;

    return (
      <MainContainer>
        <HeroImage
          title={i18n('marketingConsentPage.heroImage.title')}
          ingress={i18n('marketingConsentPage.heroImage.ingress')}
        />
        <LoginPrompt
          loggedIn={loggedIn}
          navigateTo={this._navigateToLoggedInForm}
          title={i18n('loginToMarketingConsent.title')}
          ingress={i18n('loginToMarketingConsent.ingress')}
          noAccountText={i18n('loginToMarketingConsent.noAccountText')}
          buttonText={i18n('loginToMarketingConsent.managePreferences')}
          redirUri={window.location.href + '/manage-preferences'}
        />
      </MainContainer>
    );
  }

  private _navigateToLoggedInForm = () => this.props.history.push(`/marketing-consent/manage-preferences`);
}

export const marketingContentMapStateToProps = (state: RootState) => {
  return {
    checkedMarketingConsents: state.checkedMarketingConsent.isChecked,
    marketingConsentGroup: state.checkedMarketingConsent.marketingConsentGroup,
    loggedIn: !!safeGet(state, 'login', 'token'),
    buttonStatus: state.checkedMarketingConsent.canProceedFurther,
    changesSavedSuccessfully: state.checkedMarketingConsent.changesSaved,
    token: safeGet(state, 'login', 'token'),
    profile: state.exportData ? state.exportData.profile : undefined,
  };
};

export const marketingConsentMapDispatchToProps = (dispatch: any) => {
  return {
    fetchConsentsInitData: (accountType: FinnairAccountType) => {
      dispatch(createFetchDataFromBackendAction(accountType));
    },
    handleToggleMarketingConsent: (item: string) => {
      dispatch(toggleMarketingConsent(item));
    },
    handleSubmitMarketingConsents: (accountType: FinnairAccountType) => {
      dispatch(createSubmitMarketingConsentsAction(accountType));
    },
    fetchUserProfile: (token: string) => {
      dispatch(fetchUserProfile(token));
    },
  };
};

export default connect(marketingContentMapStateToProps, marketingConsentMapDispatchToProps)(MarketingConsentScreen);
